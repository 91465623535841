import { Controller, useForm } from "react-hook-form";
import {
  NanoCheckbox,
  NanoCheckboxGroup,
  NanoIcon,
  NanoIconButton,
  NanoInput,
} from "@nanoporetech-digital/components-react";
import { Registration } from "../../services/janus/janus.types";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useGetRegistrationQuery,
  useSetPasswordMutation,
  useTransitionRegistrationMutation,
  useUpdateRegistrationMutation,
} from "../../services/janus/janus.api";
import { useAppSelector } from "../../app/hooks";
import "./sc-add-account.form.css";
import {
  applyErrorsToForm,
  getConfirmPasswordError,
  getFormErrors,
  nanoComponentShowError,
} from "./sc-add-account.utils";

export default function ScAddAccountForm() {
  const { id } = useAppSelector((state) => state.scAddAccount);

  if (id === undefined) throw new Error("Registration ID is undefined");

  const [confirmPassword, setConfirmPassword] = useState<
    string | null | undefined
  >("");

  const [maskPassword, setMaskPassword] = useState<boolean>(true);

  const formRef = useRef<HTMLFormElement>(null);

  const { data, refetch } = useGetRegistrationQuery(id);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { dirtyFields, isSubmitted },
  } = useForm<Registration>({
    defaultValues: data,
  });

  const fields = watch();

  const errorsToShow = useMemo(
    () => getFormErrors({ data, dirtyFields, isSubmitted }),
    // eslint-disable-next-line
    [data, dirtyFields, isSubmitted, fields]
  );

  useEffect(() => {
    const error = getConfirmPasswordError({
      password: fields.password,
      confirmPassword,
    });
    nanoComponentShowError({ ref: formRef, name: "confirm_password", error });
  }, [formRef, fields, confirmPassword]);

  useEffect(() => {
    applyErrorsToForm(errorsToShow, formRef);
  }, [errorsToShow, formRef]);

  const [updateRegistration] = useUpdateRegistrationMutation();

  const [transitionRegistration] = useTransitionRegistrationMutation();

  const [setPassword] = useSetPasswordMutation();

  const submit = useCallback(
    async (unsavedChanges: Partial<Registration>) => {
      const { password, ...nonPasswordUnsavedChanges } = unsavedChanges;

      if (typeof password === "string") {
        await setPassword({ id, password });
      }

      const updated = await updateRegistration({
        id,
        data: nonPasswordUnsavedChanges,
      }).unwrap();
      const transitionName = updated.actions.determined.transition;

      if (transitionName !== null) {
        const transition = updated.actions.all
          .filter(({ available }) => available)
          .find(({ name }) => name === transitionName);

        if (transition) {
          await transitionRegistration({ id, transitionName }).unwrap();
        }
      }

      reset(
        { ...updated, password: getValues("password") },
        { keepIsSubmitted: true }
      );

      refetch();
    },
    [
      updateRegistration,
      id,
      transitionRegistration,
      setPassword,
      getValues,
      reset,
      refetch,
    ]
  );

  return (
    <form
      ref={formRef}
      data-testid="terms-agreement-form"
      onSubmit={handleSubmit(submit)}
    >
      <div className="app-page__header">
        <h2 className="heading heading--two">Complete registration</h2>
        <p>
          A new Oxford Nanopore Technologies account is being set up for{" "}
          <strong>{data?.organisation_name}</strong>. Please complete the
          details below to finish setting up the account.
        </p>
      </div>
      {data?.existing_user_account_id === null ? (
        <Controller
          control={control}
          render={({ field }) => (
            <>
              <NanoInput
                name="password"
                data-testid="password-input"
                label="Create a password"
                type={maskPassword ? "password" : "text"}
                onNanoChange={(event) => field.onChange(event.target.value)}
                onNanoBlur={field.onBlur}
                float-label={true}
              >
                <NanoIconButton
                  data-testid="show-password"
                  label="Show password"
                  slot="end"
                  iconName={maskPassword ? "regular/eye" : "regular/eye-slash"}
                  onClick={() => {
                    setMaskPassword(!maskPassword);
                  }}
                />
                <ul slot="helper-end">
                  {data?.actions.determined.combined_validation
                    .filter(({ field }) => field === "password")
                    .map((validation) => (
                      <li key={validation.name}>{validation.reason}</li>
                    ))}
                </ul>
              </NanoInput>
              <NanoInput
                data-testid="password-confirm-input"
                label="Confirm password"
                name="confirm_password"
                type={maskPassword ? "password" : "text"}
                onNanoChange={(event) => setConfirmPassword(event.target.value)}
                value={confirmPassword}
                float-label={true}
              >
                <NanoIconButton
                  data-testid="show-confirm-password"
                  label="Show password"
                  slot="end"
                  iconName={maskPassword ? "regular/eye" : "regular/eye-slash"}
                  onClick={() => {
                    setMaskPassword(!maskPassword);
                  }}
                />
                <span slot="helper-end" />
              </NanoInput>
            </>
          )}
          name="password"
        />
      ) : null}

      <Controller
        control={control}
        render={({ field }) => (
          <NanoCheckboxGroup
            data-testid="use_military_nuclear"
            data-name="use_military_nuclear"
            legend="Will the products be used for military or nuclear applications or military or nuclear end-use purposes?"
          >
            <NanoCheckbox
              data-testid="use_military_nuclear_yes"
              label="Yes"
              type="radio"
              checked={field.value === "yes" || field.value === true}
              onNanoChange={(event) =>
                event.target.checked ? field.onChange(true) : null
              }
            />
            <NanoCheckbox
              data-testid="use_military_nuclear_no"
              label="No"
              type="radio"
              checked={field.value === "no" || field.value === false}
              onNanoChange={(event) =>
                event.target.checked ? field.onChange(false) : null
              }
            />
          </NanoCheckboxGroup>
        )}
        name="use_military_nuclear"
      />
      <Controller
        control={control}
        render={({ field }) => (
          <NanoCheckboxGroup
            data-name="use_resell"
            legend="Are you intending to resell, distribute or provide the products to a third party?"
          >
            <NanoCheckbox
              label="Yes"
              type="radio"
              data-testid="use_resell_yes"
              checked={field.value === "yes" || field.value === true}
              onNanoChange={(event) =>
                event.target.checked ? field.onChange(true) : null
              }
            />
            <NanoCheckbox
              data-testid="use_resell_no"
              label="No"
              type="radio"
              checked={field.value === "no" || field.value === false}
              onNanoChange={(event) =>
                event.target.checked ? field.onChange(false) : null
              }
            />
          </NanoCheckboxGroup>
        )}
        name="use_resell"
      />
      <Controller
        control={control}
        render={({ field }) => (
          <NanoInput
            type="textarea"
            rows={4}
            data-testid="use_statement"
            label="Please provide information regarding your intended use of Nanopore
          products and technology:"
            onNanoChange={(event) => field.onChange(event.target.value)}
            value={field.value}
            name="use_statement"
          />
        )}
        name="use_statement"
      />

      {data?.terms_and_conditions_bespoke === false ? (
        <>
          <p>
            Please review our{" "}
            <a href="https://nanoporetech.com/about/terms-conditions-policies">
              terms and conditions
            </a>
            .
          </p>
          <Controller
            control={control}
            render={({ field }) => (
              <NanoCheckboxGroup data-name="terms_and_conditions_agreement">
                <NanoCheckbox
                  data-testid="terms-agreement-checkbox"
                  label="I agree to Oxford Nanopore's terms and conditions"
                  type="checkbox"
                  checked={field.value === true || field.value === "yes"}
                  onNanoChange={(event) => field.onChange(event.target.checked)}
                  onNanoBlur={field.onBlur}
                />
              </NanoCheckboxGroup>
            )}
            name="terms_and_conditions_agreement"
          />
        </>
      ) : null}
      <div>
        <button className="button button--primary">
          Save
          <NanoIcon name="regular/chevron-right" />
        </button>
      </div>
    </form>
  );
}
